html.hc-nav-yscroll {
    overflow-y: scroll
}

li.nav-close {
    display: none !important;
}

li.nav-close .nav-item-wrapper {
    display: none;
}

body.hc-nav-open {
    overflow: visible;
    position: fixed;
    width: 100%;
    min-height: 100%
}

.hc-offcanvas-nav {
    visibility: hidden;
    display: none;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 9999
}

.hc-offcanvas-nav.is-ios * {
    cursor: pointer !important
}

.hc-offcanvas-nav .nav-container {
    position: fixed;
    z-index: 9998;
    top: 0;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    transition: transform .4s ease
}

.hc-offcanvas-nav .nav-wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -ms-scroll-chaining: none;
    overscroll-behavior: none
}

.hc-offcanvas-nav .nav-content {
    height: 100%
}

.hc-offcanvas-nav .nav-wrapper-0>.nav-content {
    overflow: scroll;
    overflow-x: visible;
    overflow-y: auto;
    box-sizing: border-box
}

.hc-offcanvas-nav ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.hc-offcanvas-nav li {
    position: relative;
    display: block
}

.hc-offcanvas-nav li.level-open>.nav-wrapper {
    visibility: visible
}

.hc-offcanvas-nav li:not(.custom-content) a {
    position: relative;
    display: block;
    box-sizing: border-box;
    cursor: pointer
}

.hc-offcanvas-nav li:not(.custom-content) a[disabled] {
    cursor: not-allowed
}

.hc-offcanvas-nav li:not(.custom-content) a,
.hc-offcanvas-nav li:not(.custom-content) a:hover {
    text-decoration: none
}

.hc-offcanvas-nav input[type="checkbox"] {
    display: none
}

.hc-offcanvas-nav label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    cursor: pointer
}

.hc-offcanvas-nav .nav-item {
    position: relative;
    display: block;
    box-sizing: border-box
}

.hc-offcanvas-nav.disable-body::after,
.hc-offcanvas-nav .nav-wrapper::after {
    content: '';
    position: fixed;
    z-index: 9990;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease .4s, opacity .4s ease
}

.hc-offcanvas-nav.disable-body.nav-open::after,
.hc-offcanvas-nav .sub-level-open::after {
    visibility: visible;
    opacity: 1;
    transition-delay: .05s
}

.hc-offcanvas-nav:not(.nav-open)::after {
    pointer-events: none
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper::after {
    display: none
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper.nav-wrapper-0 {
    max-height: 100vh
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper.nav-wrapper-0>.nav-content {
    overflow: scroll;
    overflow-x: visible;
    overflow-y: auto;
    box-sizing: border-box;
    max-height: 100vh
}

.hc-offcanvas-nav.nav-levels-expand ul .nav-wrapper {
    min-width: 0;
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
    transition: height 0s ease .4s
}

.hc-offcanvas-nav.nav-levels-expand .level-open>.nav-wrapper {
    max-height: none;
    overflow: visible;
    visibility: visible
}

.hc-offcanvas-nav.nav-levels-overlap .nav-content {
    overflow: scroll;
    overflow-x: visible;
    overflow-y: auto;
    box-sizing: border-box;
    max-height: 100vh
}

.hc-offcanvas-nav.nav-levels-overlap .nav-wrapper {
    max-height: 100vh
}

.hc-offcanvas-nav.nav-levels-overlap ul .nav-wrapper {
    position: absolute;
    z-index: 9999;
    top: 0;
    height: 100%;
    visibility: hidden;
    transition: visibility 0s ease .4s, transform .4s ease
}

.hc-offcanvas-nav.nav-levels-overlap ul li.nav-parent {
    position: static
}

.hc-offcanvas-nav.nav-levels-overlap ul li.level-open>.nav-wrapper {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: transform .4s ease
}

.hc-offcanvas-nav.nav-position-left {
    left: 0
}

.hc-offcanvas-nav.nav-position-left .nav-container {
    left: 0
}

.hc-offcanvas-nav.nav-position-left.nav-levels-overlap li .nav-wrapper {
    left: 0;
    transform: translate3d(-100%, 0, 0)
}

.hc-offcanvas-nav.nav-position-right {
    right: 0
}

.hc-offcanvas-nav.nav-position-right .nav-container {
    right: 0
}

.hc-offcanvas-nav.nav-position-right.nav-levels-overlap li .nav-wrapper {
    right: 0;
    transform: translate3d(100%, 0, 0)
}

.hc-offcanvas-nav.nav-position-top {
    top: 0
}

.hc-offcanvas-nav.nav-position-top .nav-container {
    top: 0;
    width: 100%
}

.hc-offcanvas-nav.nav-position-top.nav-levels-overlap li .nav-wrapper {
    left: 0;
    transform: translate3d(0, -100%, 0)
}

.hc-offcanvas-nav.nav-position-bottom {
    top: auto;
    bottom: 0
}

.hc-offcanvas-nav.nav-position-bottom .nav-container {
    top: auto;
    bottom: 0;
    width: 100%
}

.hc-offcanvas-nav.nav-position-bottom.nav-levels-overlap li .nav-wrapper {
    left: 0;
    transform: translate3d(0, 100%, 0)
}

.hc-offcanvas-nav.nav-open[class*='hc-nav-'] div.nav-container {
    transform: translate3d(0, 0, 0)
}

.hc-offcanvas-nav.rtl li {
    text-align: right
}

.hc-offcanvas-nav.rtl .nav-next,
.hc-offcanvas-nav.rtl li.nav-back span,
.hc-offcanvas-nav.rtl li.nav-close span {
    left: 0;
    right: unset
}

.hc-nav-trigger.hc-nav-1,
.hc-nav-trigger {
    display: none;
}

.hc-nav-trigger {
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: none;
    top: 18px;
    width: 30px;
    min-height: 24px;
    right: 8px;
}

.hc-nav-trigger span {
    width: 30px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: 50% 50%
}

.hc-nav-trigger span,
.hc-nav-trigger span::before,
.hc-nav-trigger span::after {
    display: block;
    position: absolute;
    left: 0;
    height: 2px;
    border-radius: 2px;
    background: #000000;
    transition: all .2s ease
}

.hc-nav-trigger span::before,
.hc-nav-trigger span::after {
    content: '';
    width: 100%
}

.hc-nav-trigger span::before {
    top: -10px
}

.hc-nav-trigger span::after {
    bottom: -10px
}

.hc-nav-trigger.toggle-open span {
    background: rgba(0, 0, 0, 0);
    transform: rotate(45deg)
}

.hc-nav-trigger.toggle-open span::before {
    transform: translate3d(0, 10px, 0)
}

.hc-nav-trigger.toggle-open span::after {
    transform: rotate(-90deg) translate3d(10px, 0, 0)
}

.hc-offcanvas-nav::after,
.hc-offcanvas-nav .nav-wrapper::after {
    background: rgba(0, 0, 0, 0.3)
}

.hc-offcanvas-nav .nav-container,
.hc-offcanvas-nav .nav-wrapper,
.hc-offcanvas-nav ul {
    background: #fff;
}

.hc-offcanvas-nav h2 {
    font-size: 19px;
    font-weight: normal;
    text-align: left;
    margin-bottom: 0px;
    padding: 20px 17px;
    background: #343a40;
    color: #fff;
}

.hc-offcanvas-nav .nav-item {
    padding: 14px 17px;
    font-size: 15px;
    color: #343a40;
    z-index: 1;
    background: rgba(0, 0, 0, 0);
    border-bottom: 1px solid #edf1f4
}

.hc-offcanvas-nav .nav-item:focus,
.hc-offcanvas-nav .nav-item:focus-within {
    z-index: 10
}

.hc-offcanvas-nav .nav-item-wrapper {
    position: relative
}

.hc-offcanvas-nav .nav-close:focus,
.hc-offcanvas-nav .nav-next:focus,
.hc-offcanvas-nav .nav-back:focus {
    z-index: 10
}

.hc-offcanvas-nav li:not(.custom-content) a {
    padding: 14px 17px;
    font-size: 13px;
    color: #343a40;
    z-index: 1;
    background: rgba(0, 0, 0, 0);
    border-bottom: 1px solid #edf1f4
}

.hc-offcanvas-nav li:not(.custom-content) a[disabled] {
    color: rgb(128, 130, 132);
}

.hc-offcanvas-nav:not(.touch-device) li:not(.custom-content) a:hover {
    background: #edf1f4;
}

.hc-offcanvas-nav ul:first-of-type:not(:first-child)>li:first-child:not(.nav-back):not(.nav-close)>a {
    border-top: 1px solid #edf1f4;
    margin-top: -1px
}

.hc-offcanvas-nav li {
    text-align: left
}

.hc-offcanvas-nav li.nav-close a,
.hc-offcanvas-nav li.nav-back a {
    background: #edf1f4;
    border-top: 1px solid #edf1f4;
    border-bottom: 1px solid #edf1f4
}

.hc-offcanvas-nav li.nav-close a:hover,
.hc-offcanvas-nav li.nav-back a:hover {
    background: #2b5c8d
}

.hc-offcanvas-nav li.nav-close:not(:first-child) a,
.hc-offcanvas-nav li.nav-back:not(:first-child) a {
    margin-top: -1px
}

.hc-offcanvas-nav li.nav-parent .nav-item:last-child {
    padding-right: 58px
}

.hc-offcanvas-nav li.nav-parent .nav-item:not(:last-child) {
    margin-right: 44px
}

.hc-offcanvas-nav li.nav-close span,
.hc-offcanvas-nav li.nav-parent .nav-next,
.hc-offcanvas-nav li.nav-back span {
    width: 45px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    cursor: pointer;
    transition: background .2s ease
}

.hc-offcanvas-nav li.nav-close span::before,
.hc-offcanvas-nav li.nav-close span::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    margin-top: -3px;
    border-top: 2px solid #343a40;
    border-left: 2px solid #343a40
}

.hc-offcanvas-nav li.nav-close span::before {
    margin-left: -6px;
    transform: rotate(135deg)
}

.hc-offcanvas-nav li.nav-close span::after {
    transform: rotate(-45deg)
}

.hc-offcanvas-nav a.nav-next {
    border-left: 1px solid #edf1f4
}

.hc-offcanvas-nav .nav-next::before,
.hc-offcanvas-nav li.nav-back span::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin-left: -2px;
    box-sizing: border-box;
    border-top: 2px solid #343a40;
    border-left: 2px solid #343a40;
    transform-origin: center
}

.hc-offcanvas-nav .nav-next::before {
    transform: translate(-50%, -50%) rotate(135deg)
}

.hc-offcanvas-nav li.nav-back span::before {
    transform: translate(-50%, -50%) rotate(-45deg)
}

.hc-offcanvas-nav.nav-position-left.nav-open .nav-wrapper {
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2)
}

.hc-offcanvas-nav.nav-position-right.nav-open .nav-wrapper {
    box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2)
}

.hc-offcanvas-nav.nav-position-right .nav-next::before {
    margin-left: 0;
    margin-right: -2px;
    transform: translate(-50%, -50%) rotate(-45deg)
}

.hc-offcanvas-nav.nav-position-right li.nav-back span::before {
    margin-left: 0;
    margin-right: -2px;
    transform: translate(-50%, -50%) rotate(135deg)
}

.hc-offcanvas-nav.nav-position-top.nav-open .nav-wrapper {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)
}

.hc-offcanvas-nav.nav-position-top .nav-next::before {
    margin-left: 0;
    margin-right: -2px;
    transform: translate(-50%, -50%) rotate(-135deg)
}

.hc-offcanvas-nav.nav-position-top li.nav-back span::before {
    margin-left: 0;
    margin-right: -2px;
    transform: translate(-50%, -50%) rotate(45deg)
}

.hc-offcanvas-nav.nav-position-bottom.nav-open .nav-wrapper {
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2)
}

.hc-offcanvas-nav.nav-position-bottom .nav-next::before {
    margin-left: 0;
    margin-right: -2px;
    transform: translate(-50%, -50%) rotate(45deg)
}

.hc-offcanvas-nav.nav-position-bottom li.nav-back span::before {
    margin-left: 0;
    margin-right: -2px;
    transform: translate(-50%, -50%) rotate(-135deg)
}

.hc-offcanvas-nav.nav-levels-expand .nav-container ul .nav-wrapper,
.hc-offcanvas-nav.nav-levels-none .nav-container ul .nav-wrapper {
    box-shadow: none;
    background: transparent
}

.hc-offcanvas-nav.nav-levels-expand .nav-container ul h2,
.hc-offcanvas-nav.nav-levels-none .nav-container ul h2 {
    display: none
}

.hc-offcanvas-nav.nav-levels-expand .nav-container ul ul .nav-item,
.hc-offcanvas-nav.nav-levels-none .nav-container ul ul .nav-item {
    font-size: 14px
}

.hc-offcanvas-nav.nav-levels-expand .nav-container li,
.hc-offcanvas-nav.nav-levels-none .nav-container li {
    transition: background .3s ease
}

.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open {
    background: #2e6296
}

.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open a,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open a {
    border-bottom: 1px solid #295887
}

.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open a:hover,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open a:hover {
    background: #2f649a
}

.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open>.nav-item .nav-next::before,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open>.nav-item .nav-next::before {
    margin-top: 2px;
    transform: translate(-50%, -50%) rotate(45deg)
}

.hc-offcanvas-nav.nav-levels-expand .nav-container .nav-next::before,
.hc-offcanvas-nav.nav-levels-none .nav-container .nav-next::before {
    margin-top: -2px;
    transform: translate(-50%, -50%) rotate(-135deg)
}

.hc-offcanvas-nav.rtl a.nav-next {
    border-left: none;
    border-right: 1px solid #edf1f4
}

.hc-offcanvas-nav.rtl li.nav-parent .nav-item {
    padding-right: 17px;
    padding-left: 58px
}

.cf::before,
.cf::after {
    content: '';
    display: block;
    height: 0;
    overflow: hidden
}

.cf::after {
    clear: both
}

#container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    background: linear-gradient(-134deg, #517FA4 0%, #243949 100%)
}

.wrapper {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px
}

#main-nav {
    display: none
}

@media screen and (min-width: 800px) {
    main .actions {
        display: flex;
        flex-wrap: wrap
    }
    main .actions div {
        float: left;
        flex: 1 1 33.33%;
        max-width: 33.33%
    }
    main .actions.position div {
        float: left;
        flex: 1 1 25%;
        max-width: 25%
    }
}

main .button {
    position: relative;
    display: block;
    padding: 18px 30px 16px;
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 1px;
    text-decoration: none;
    color: #243949;
    cursor: pointer;
    background: #fffce1;
    border-radius: 30px/80px;
    transition: all .1s ease
}

main .button:not(.active):hover {
    color: #d5af63
}

main .button.active {
    background: #dab977
}

.hc-offcanvas-nav .nav-wrapper-0>.nav-content {
    padding-bottom: 63px
}

.hc-offcanvas-nav h2 {
    font-weight: 400
}

.hc-offcanvas-nav a {
    font-size: 16px
}

.hc-offcanvas-nav li .custom-message {
    font-size: 12px
}

.hc-offcanvas-nav li .custom-message a {
    color: #343a40;
    font-size: 13px
}

.hc-offcanvas-nav li .custom-message a:hover {
    text-decoration: none
}

.hc-offcanvas-nav li.search .nav-item {
    padding-top: 0
}

.hc-offcanvas-nav li.search input[type="text"] {
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    color: #343a40;
    background: rgb(255, 255, 255);
    padding: 5px 10px;
    box-shadow: none;
    border: 1px solid rgb(237, 241, 244);
}

.hc-offcanvas-nav li.add>.nav-item-wrapper a::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    text-indent: 0;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    font-size: 18px;
    vertical-align: top;
    speak: never;
    content: 'add'
}

.hc-offcanvas-nav li.new>.nav-item-wrapper .nav-item::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    text-indent: 0;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    font-size: 18px;
    vertical-align: top;
    speak: never;
    content: 'fiber_new'
}

.hc-offcanvas-nav li.cryptocurrency>.nav-item-wrapper .nav-item::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    text-indent: 0;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    font-size: 18px;
    vertical-align: top;
    speak: never;
    content: 'local_atm'
}

.hc-offcanvas-nav li.devices>.nav-item-wrapper .nav-item::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    text-indent: 0;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    font-size: 18px;
    vertical-align: top;
    speak: never;
    content: 'devices'
}

.hc-offcanvas-nav li.mobile>.nav-item-wrapper .nav-item::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    text-indent: 0;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    font-size: 18px;
    vertical-align: top;
    speak: never;
    content: 'phone_android'
}

.hc-offcanvas-nav li.television>.nav-item-wrapper .nav-item::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    text-indent: 0;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    font-size: 18px;
    vertical-align: top;
    speak: never;
    content: 'desktop_windows'
}

.hc-offcanvas-nav li.camera>.nav-item-wrapper .nav-item::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    text-indent: 0;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    font-size: 18px;
    vertical-align: top;
    speak: never;
    content: 'camera_alt'
}

.hc-offcanvas-nav li.magazines>.nav-item-wrapper .nav-item::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    text-indent: 0;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    font-size: 18px;
    vertical-align: top;
    speak: never;
    content: 'import_contacts'
}

.hc-offcanvas-nav li.store>.nav-item-wrapper .nav-item::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    text-indent: 0;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    font-size: 18px;
    vertical-align: top;
    speak: never;
    content: 'store'
}

.hc-offcanvas-nav li.collections>.nav-item-wrapper .nav-item::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    text-indent: 0;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    font-size: 18px;
    vertical-align: top;
    speak: never;
    content: 'collections'
}

.hc-offcanvas-nav li.nolink>.nav-item-wrapper .nav-item::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    text-indent: 0;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    font-size: 18px;
    vertical-align: top;
    speak: never;
    content: 'format_clear'
}

.hc-offcanvas-nav li.disabled>.nav-item-wrapper .nav-item::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    text-indent: 0;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    font-size: 18px;
    vertical-align: top;
    speak: never;
    content: 'block'
}

.hc-offcanvas-nav.nav-position-top ul.bottom-nav,
.hc-offcanvas-nav.nav-position-bottom ul.bottom-nav {
    position: relative;
    border-top: none
}

.hc-offcanvas-nav.nav-position-top .nav-wrapper-0>.nav-content,
.hc-offcanvas-nav.nav-position-bottom .nav-wrapper-0>.nav-content {
    padding-bottom: 0
}

.hc-offcanvas-nav ul.bottom-nav {
    position: absolute;
    z-index: 10;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    border-top: 1px solid #edf1f4
}

.hc-offcanvas-nav ul.bottom-nav li {
    flex: auto
}

.hc-offcanvas-nav ul.bottom-nav li a {
    padding: 10px;
    text-align: center;
    height: 100%;
    border-bottom: none
}

.hc-offcanvas-nav ul.bottom-nav li svg {
    fill: #343a40;
    display: inline-block;
    vertical-align: middle
}

.hc-offcanvas-nav ul.bottom-nav li.github svg {
    width: 17px;
    height: 17px
}

.hc-offcanvas-nav ul.bottom-nav li.ko-fi svg {
    width: 21px;
    height: 21px
}

.hc-offcanvas-nav ul.bottom-nav li.email svg {
    width: 19px;
    height: 19px
}