
html.hc-nav-yscroll {
  -webkit-overflow-y-scrolling: touch;
}

.list-card .favourite-heart-like {
  right: 8px;
  top: 8px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}


.list-card .favourite-heart-like i {
  background:  #dc3545;
  color:#fff;
  width: 23px;
  height: 23px;
  display: inline-block;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  border-radius: 50px;
}
.or-img {
  height: 300px;
}
.fade-anim {
  background-color: rgb(0, 0, 0,0.7)
  
}
.modal-backdrop.fade.show {
  display: none;
}
.read-notification {
  background-color: #fff;
}
a.link-onclick {
  color: #000;
  cursor: pointer;
}
a.link-onclick:hover {
  color:#00d2ff;
}
.slick-slide img {
  background-size: cover;
}
.btn-outline-light:hover {
  color: #212529 !important;
}
.modal-footer, .modal-header {
  height: auto !important;
  min-height: 56px !important;
}
.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.modal-footer {
  margin:10px;
} 
.btn-lg {
  font-size: 14px;
  padding: 16px 16px;
  font-weight: 600;
  line-height: 22px;
}
.osahan-menu-fotter .col a {
  padding: 0px;
  padding-top: 8px;
  padding-left:2px;
}
.moblie-search {
  width: 14rem;
}
.t-pre {
  text-align: right;
}

.Z4sK8 {
  color: #fff;  
  padding: 30px 0 0px;
  height: 100%;
}
._2FyFZ {
  transform: translateZ(0);
  transition: transform .2s ease-in-out;
  border: 1px solid #bec0c8;
  position: relative;
}
.QWCzK {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background-color: #000;
  display: inline-block;
  padding-right: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  position: absolute;
  top: -13px;
  left: -6px;
}
._3F2Nk {
  padding: 15px 26px 14px 25px;
}
.DM5zR:first-child {
  margin-top: 0;
}
.DM5zR {
  z-index: -1;
  line-height: 17px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 16px;
}
._2icon {
  margin-right: 10px;
  font-size: 19px;
  -ms-flex-item-align: baseline;
  align-self: baseline;
}
._3small {
  font-size: 11px;
  color: #fff;
  opacity: .9;
  line-height: 1.6;
  font-weight: 500;
}
.sticky_2 {
  position: fixed;
  position: -webkit-sticky;
  top: 8%;
  width: 100%;
}
.aline-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  
}
img.circular_image {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  max-width: inherit;
  border:1px solid #fff;
}
@media only screen and (max-width: 600px) { 
  .py-6 {  
    padding-bottom: 3.6rem;
  }
  .slick-arrow, .slick-arrow:focus, .slick-arrow:hover {
    margin: 0px 25px;
  }
  .t-pre {
    padding-top: 5px;
    text-align: end;
    margin-left: 0rem !important;
  }
}
@media only screen  and (max-device-width:726px) {
  .dt {
    padding-top: 2.5rem;
  }
  .dg {
    margin-top: 2.5rem;
  }
  .brand-wrap img {
    margin-top: 15px;
   }
  .nt-6 {
    padding-top:3.5rem;
  }
  #hid {
    display:none;
  }
  /* .sticky-hd {
    padding-top: 40%;
   } */
}
/* Portrait */
@media only screen 
  and (min-device-width: 320px)   and (max-device-width: 480px)   and (-webkit-min-device-pixel-ratio: 2)   and (orientation: portrait) {
    .nb-1 {
      margin-top: 0.4rem;
    }
}
@media only screen    and (min-device-width: 320px)   and (max-device-width: 568px)   and (-webkit-min-device-pixel-ratio: 2)   and (orientation: portrait) {
  .nb-1 {
    margin-top: 0.4rem;
  }
}

@media only screen    and (min-device-width: 375px)    and (max-device-width: 667px)   and (-webkit-min-device-pixel-ratio: 2)   and (orientation: portrait) { 
  .nb-1 {
    margin-top: 0.4rem;
  }
}
@media only screen   and (min-device-width: 414px)   and (max-device-width: 736px)   and (-webkit-min-device-pixel-ratio: 3)   and (orientation: portrait) { 
  .nb-1 {
    margin-top: 0.4rem;
  }
  .sticky_2 {
    top: 8%;
  }
}
/* Portrait */
@media only screen 
  and (min-device-width: 375px)    and (max-device-width: 812px)    and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    .dg {
      padding-top: 2.5rem;
    }
    .nb-1 {
      margin-top: 0.4rem;
    }
}
/* Portrait */
@media only screen   and (min-device-width: 768px)   and (max-device-width: 1024px)   and (orientation: portrait)   and (-webkit-min-device-pixel-ratio: 1) {
    .py-6 {  
      padding-bottom: 3.4rem;
    }
    /* .sticky-hd {
      padding-top: 21%;
     }  */
     .sticky_2 {
      top: 6%;
     } 
}
/* Landscape */
@media only screen    and (min-device-width: 768px)   and (max-device-width: 1024px)   and (orientation: landscape)   and (-webkit-min-device-pixel-ratio: 1) {
  .py-6 {  
    padding-bottom: 3.3rem;
  }
  .moblie-search {
    width: 16rem;
}

}
/* Landscape */


@media only screen 
  and (min-device-width: 414px)  and (max-device-width: 736px)  and (-webkit-min-device-pixel-ratio: 3)   and (orientation: landscape) { 
    .py-6 {  
      padding-bottom: 3.3rem;
    }
    .moblie-search {
      width: 16rem;
  }
  .dt{
    padding-top: 2.5rem;
  }
  .dg {
    padding-bottom: 2.5rem;
  }
  
}
/* Portrait and Landscape */
@media only screen    and (min-device-width: 320px)   and (max-device-width: 780px)   and (-webkit-min-device-pixel-ratio: 2) {  
  .py-6 {  
    padding-bottom: 3.5rem;
  }
}
@media only screen    and (min-device-width: 375px)   and (max-device-width: 667px)   and (-webkit-min-device-pixel-ratio: 2) { 
  .py-6 {  
    padding-bottom: 3.5rem;
  }
  
}
/* Landscape */
@media only screen 
  and (min-device-width: 414px)   and (max-device-width: 736px)   and (-webkit-min-device-pixel-ratio: 3)  and (orientation: landscape) { 
    .moblie-search {
      width: 16rem;
  }
}
@media only screen 
  and (min-device-width: 375px)    and (max-device-width: 667px)   and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape) { 
    .moblie-search {
      width: 16rem;
  }
  .sticky_2 {
    top: 16%;
  }
   /* .sticky-hd {
    padding-top: 28%;
}  */

}
@media only screen 
  and (min-device-width: 360px)    and (max-device-width: 668px)   and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape) { 
     /* .sticky-hd {
      padding-top: 28%;
  }  */
  }
/* Landscape */
@media only screen 
  and (min-device-width: 320px)   and (max-device-width: 568px)   and (-webkit-min-device-pixel-ratio: 2)   and (orientation: landscape) {
    .dt{
      padding-top: 2.5rem;
    }
    .dg {
      padding-top: 2.5rem;
    }
    .py-6 {  
      padding-bottom: 3.5rem;
    }
  
}
@media only screen 
  and (min-device-width: 375px)    and (max-device-width: 667px)   and (-webkit-min-device-pixel-ratio: 2)   and (orientation: landscape) { 
    .dt{
      padding-top: 2.5rem;
    }
    .dg {
      padding-top: 2.5rem;
    }
    .py-6 {  
      padding-bottom: 3.5rem;
    }
    /* .sticky-hd {
      padding-top: 23%;
  }  */
}
@media only screen 
  and (min-device-width: 375px)   and (max-device-width: 812px)    and (-webkit-min-device-pixel-ratio: 3)   and (orientation: landscape) { 
    .dt{
      padding-top: 2.5rem;
    }
    .dg {
      padding-top: 2.5rem;
    }
}
@media only screen and (device-width: 375px)  and (device-height: 812px)   and (-webkit-device-pixel-ratio: 3) {
  .dg {
    margin-top: 2.5rem;
  }
  .py-6 {  
    padding-bottom: 3.5rem;
  }
 }

 @media only screen  and (device-width: 390px)  and (device-height: 844px)     and (-webkit-device-pixel-ratio: 3) {
  .dg {
    margin-top: 2.5rem;
  }
  .sticky_2 {
    top: 7%;
  }
  }

  @media only screen   and (device-width: 375px) and (device-height: 812px)   and (-webkit-device-pixel-ratio: 3) {
    .dg {
      margin-top: 2.5rem;
    }
    .py-6 {  
      padding-bottom: 3.5rem;
    }
   }


@media only screen and  (min-device-width: 411px)   and (max-device-width: 823px)     and (orientation: landscape) {  
  .dg {
    padding-top: 2.5rem;     
  }
  /* ----------- Non-Retina Screens ----------- */

}
@media screen  and (min-device-width: 1024px) and (max-device-width: 1400px)   and (-webkit-min-device-pixel-ratio: 1) { 
  .dg {
    padding-top: 2.5rem;     
  }
  .login-page video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;  
    object-fit: cover;
  }
  .hc-nav-trigger.hc-nav-1 {
    align-items: center;
    display: block;
    top: auto;
  }
}
@media screen  and (min-device-width: 1400px) and (max-device-width: 1600px)   and (-webkit-min-device-pixel-ratio: 1) { 
  .dg {
    padding-top: 2.5rem;     
  }
  .hc-nav-trigger.hc-nav-1 {
    align-items: center;
    display: block;
    top: auto;
  }
}
/* Chat  UI */

.messages {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  color: rgba(255, 255, 255, 0.5);
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
}

.messages .messages-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 101%;
  width: 100%;
}

.messages .message {
  clear: both;
  float: left;
  padding: 6px 10px 7px;
  /* padding: 7px 7px 0px; */
  border-radius: 10px 10px 10px 0;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  margin: 8px 0;
  font-size: 12px;
  line-height: 1.4;
  margin-left: 35px;
  position: relative;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.messages .message .timestamp {
  position: absolute;
  bottom: -15px;
  font-size: 9px;
  color: #707070;
}

.messages .message::before {
  content: '';
  position: absolute;
  bottom: -6px;
  border-top: 6px solid rgb(245, 245, 245);
  left: 0;
  border-right: 7px solid transparent;
}

.messages .message .avatar {
  position: absolute;
  z-index: 1;
  bottom: -15px;
  left: -35px;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 2px solid rgba(0, 0, 0, 0.24);
}

.messages .message .avatar img {
  width: 100%;
  height: auto;
}

.messages .message.message-personal {
  float: right;
  color: #fff !important;
  text-align: right;
  word-break: break-all;
  background-color: #00a4ef;
  border-radius: 10px 10px 0 10px;
}

.messages .message.message-personal::before {
  left: auto;
  right: 0;
  border-right: none;
  border-left: 5px solid transparent;
  border-top: 4px solid #00a4ef;
  bottom: -4px;
}

.messages .message:last-child {
  margin-bottom: 30px;
}

.messages .message.new {
  color: #000;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-animation: bounce 500ms linear both;
  animation: bounce 500ms linear both;
}

.messages .message.loading::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  border: none;
  -webkit-animation-delay: .15s;
  animation-delay: .15s;
}

.messages .message.loading span {
  display: block;
  font-size: 0;
  width: 20px;
  height: 10px;
  position: relative;
}

.messages .message.loading span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  margin-left: -7px;
}

.messages .message.loading span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  margin-left: 7px;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

.messages .message .timestamp {
  position: relative;
  bottom: -13px;
  font-size: 8px;
  color: #707070;
}

/* Compose */

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 60px;
  width: 100%;
  z-index: 2;
  margin-top: 5px;
  margin-bottom: 5px;
}

.conversation-compose div,
.conversation-compose input {
  background: #fff;
  height: 100%;
}

.conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 5px 0 0 5px;
  flex: 0 0 auto;
  margin-left: 8px;
  width: 0px;
}

.conversation-compose .message-input {
  border: 0;
  flex: 1 1 auto;
  font-size: 16px;
  margin: 0;
  outline: none;
  min-width: 70px;
  border-radius: 5px 0 0 5px;
}

.conversation-compose .photo {
  flex: 0 0 auto;
  border-radius: 0 0 5px 0;
  text-align: center;
  position: relative;
  width: 20px;
}
.conversation-compose .photo:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  top: 0;
  right: -10px;
}
.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none;
}

.conversation-compose .send .circle {
  background: #00a4ef;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation-compose .send .circle i {
  font-size: 24px;
  
}
.conversation-compose input[type=file] {
  display: none;
}

  .custom-file-upload {
      position: absolute;
      display: inline-block;
      cursor: pointer;
      color: #7d8488;
      font-size: 24px;
      transform: translate(-50%, -50%);
      position: relative;
      top: 50%;
      left: 21%;
      outline: none !important;
  }
  @media (max-width: 768px) { 
    .conversation {
      height: calc(100vh - 55px);
    }
    .conversation .conversation-container {
      height: calc(100vh - 120px);
    }
  }



.btn-outline-three {
  color:#ff0000;
  border-color: #ff0000;
}
.btn-outline-three:hover {
  color: #fff;
  background-color: #ff0000;
}
.count-number-input {
  width: 39px;
  font-size: 11px;
  border-bottom: 1px solid #6c757d;
  font-weight: bold;
  color: #5adcfc;
  background-color: #fff;
  margin: 0 5px;

}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.1rem rgb(108 117 125 / 50%);
}
/*color */


.bg-primary {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00d2ff+0,00a4ef+100 */
  background: #00d2ff;
  /* Old browsers */
  background: -moz-linear-gradient(45deg, #00d2ff 0%, #00a4ef 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #00d2ff 0%, #00a4ef 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #00d2ff 0%, #00a4ef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00d2ff', endColorstr='#00a4ef', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.btn-primary {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00d2ff+0,00a4ef+100 */
  background: #00d2ff;
  /* Old browsers */
  background: -moz-linear-gradient(45deg, #00d2ff 0%, #00a4ef 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #00d2ff 0%, #00a4ef 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #00d2ff 0%, #00a4ef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00d2ff', endColorstr='#00a4ef', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  border-color: #00d2ff;
}

.btn-primary:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00d2ff+0,00a4ef+100 */
  background: #00d2ff;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #00d2ff 0%, #00a4ef 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #00d2ff 0%, #00a4ef 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #00d2ff 0%, #00a4ef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00d2ff', endColorstr='#00a4ef', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  border-color: #00a4ef;
}

.btn-outline-primary {
  color: #00a4ef;
  border-color: #00a4ef;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background: #00d2ff;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #00d2ff 0%, #00a4ef 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #00d2ff 0%, #00a4ef 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #00d2ff 0%, #00a4ef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00d2ff', endColorstr='#00a4ef', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  border-color: #00a4ef;
}
.btn-success.focus, .btn-success:focus {
  color: #fff;
  background-color: #00a4ef;
  border-color: #00a4ef;
  box-shadow: 0 0 0 0.2rem rgb(3 169 244 / 58%);
}
.btn-success.focus, .btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgb(3 169 244 / 58%);
}
.text-primary {
  color: #00a4ef !important;
  text-decoration: none !important;
}

a {
  color: #00a4ef;
  text-decoration: none !important;
}
a:not([href]) { 
  cursor: pointer;
}
a:not([href]):hover {
  text-decoration: none;
}
a.btn.btn-outline-primary:hover {
  color: #fff;
}
p {
  color: #424242;
  letter-spacing: 0.2px;
}
.link-color:hover {
  color: #00a4ef !important;
}
.btn-link {
  font-weight: 400;
  color: #00a4ef;
  text-decoration: none !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00a4ef;
  background-color: #00a4ef;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #a5a5a5;
  border-color: #a5a5a5;
}
.list-card a:hover {
  color: #00a4ef;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border-bottom: 2px solid #00a4ef !important;
  color: #00a4ef !important;
}
.search .nav-tabs .nav-item.show .nav-link, .search .nav-tabs .nav-link.active {
  border-bottom: 2px solid #00a4ef !important;
  background-color: #00a4ef !important;
  color: #ffffff !important;
}
.btn-success {
  color: #fff;
  background-color: #00a4ef;
  border-color: #00a4ef;
}
.btn-success:hover {
  color: #fff;
  background-color: #00d2ff;
  border-color: #00d2ff;
}
.btn-green {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00d2ff;
  border-color: #00d2ff;
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #00d2ff;
  border-color: #00d2ff;
}
.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00a4efa3;
}
.c-t-order li a.active i {
  background-color: #00a4ef;
}
.login-page .form-group {
  margin-bottom: 20px;
}
.list-card .favourite-heart {
  box-shadow: none !important;
}
.list-card .favourite-heart-like {
  box-shadow: none !important;
}


/* Chat  UI */

.messages {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  color: rgba(255, 255, 255, 0.5);
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
}

.messages .messages-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 101%;
  width: 100%;
}

.messages .message {
  clear: both;
  float: left;
  padding: 6px 10px 7px;
  /* padding: 7px 7px 0px; */
  border-radius: 10px 10px 10px 0;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  margin: 8px 0;
  font-size: 12px;
  line-height: 1.4;
  margin-left: 35px;
  position: relative;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.messages .message .timestamp {
  position: absolute;
  bottom: -15px;
  font-size: 9px;
  color: #707070;
}

.messages .message::before {
  content: '';
  position: absolute;
  bottom: -6px;
  border-top: 6px solid rgb(245, 245, 245);
  left: 0;
  border-right: 7px solid transparent;
}

.messages .message .avatar {
  position: absolute;
  z-index: 1;
  bottom: -15px;
  left: -35px;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 2px solid rgba(0, 0, 0, 0.24);
}

.messages .message .avatar img {
  width: 100%;
  height: auto;
}

.messages .message.message-personal {
  float: right;
  color: #fff !important;
  text-align: right;
  word-break: break-all;
  background-color: #00a4ef;
  border-radius: 10px 10px 0 10px;
}

.messages .message.message-personal::before {
  left: auto;
  right: 0;
  border-right: none;
  border-left: 5px solid transparent;
  border-top: 4px solid #00a4ef;
  bottom: -4px;
}

.messages .message:last-child {
  margin-bottom: 30px;
}

.messages .message.new {
  color: #000;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-animation: bounce 500ms linear both;
  animation: bounce 500ms linear both;
}

.messages .message.loading::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  border: none;
  -webkit-animation-delay: .15s;
  animation-delay: .15s;
}

.messages .message.loading span {
  display: block;
  font-size: 0;
  width: 20px;
  height: 10px;
  position: relative;
}

.messages .message.loading span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  margin-left: -7px;
}

.messages .message.loading span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  margin-left: 7px;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}


.messages .message .timestamp {
  position: relative;
  bottom: -13px;
  font-size: 8px;
  color: #707070;
}

/* Compose */

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 60px;
  width: 100%;
  z-index: 2;
  margin-top: 5px;
  margin-bottom: 5px;
}

.conversation-compose div,
.conversation-compose input {
  background: #fff;
  height: 100%;
}

.conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 5px 0 0 5px;
  flex: 0 0 auto;
  margin-left: 8px;
  width: 0px;
}

.conversation-compose .message-input {
  border: 0;
  flex: 1 1 auto;
  font-size: 16px;
  margin: 0;
  outline: none;
  min-width: 70px;
  border-radius: 5px 0 0 5px;
  padding-left: 10px;
  resize: none;
  padding: 5px;
  min-height: 62px;
  max-height: 65px;
}

.conversation-compose .photo {
  flex: 0 0 auto;
  border-radius: 0 0 5px 0;
  text-align: center;
  position: relative;
  width: 20px;
}

.conversation-compose .photo:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  top: 0;
  right: -10px;
}



.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none;
}

.conversation-compose .send .circle {
  background: #00a4ef;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation-compose .send .circle i {
  font-size: 24px;
}
.conversation-compose input[type=file] {
  display: none;
}
.pwd-bg {
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #a0a0a052;
}
.pwd-bg:hover {
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #000;
}


  .custom-file-upload {
      position: absolute;
      display: inline-block;
      cursor: pointer;
      color: #7d8488;
      font-size: 24px;
      transform: translate(-50%, -50%);
      position: relative;
      top: 50%;
      left: 21%;
      outline: none !important;
  }
  @media (max-width: 768px) { 
    .conversation {
      height: calc(100vh - 55px);
    }
    .conversation .conversation-container {
      height: calc(100vh - 120px);
    }
  }
.from-full {
    display: contents;
}
img {
  max-width: 100%;
}
.image-chat {
  width: 150px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) { 
  .chat-box {
    bottom: 82px;
  }
}
.modal-open .modal {
  z-index: 999999;
}
.divider{
  width:5px;
  padding: 4px;
  height:auto;
  display:inline-block;
  }
  @media only screen and (min-width: 991px) { 
    .hightvh {
      height: 100vh;
    }
  }
  @media only screen and (max-width: 990px) {
.divider{
  width:auto;
  }
  .b-gap {
    margin-bottom: 5px;
  }
  .db-6 {
    padding-bottom: 2rem;
   }
    .hightvh {
      background: #fff;
      min-height: 100%;
    }
  }
  .error-message {
    color: #cc0033;
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    margin: 5px 0 5px 0;
   }
  .login-page .form-group {
    margin-bottom: 24px;
  }
  @media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1260px;
    }
    .hc-nav-trigger.hc-nav-1 {
      top:auto;
      align-items: center;
    }
  }
  .brand-wrap img {
    height: 65px;
    margin: 5px 0px;
}
@media (max-width: 992px) {
  .hc-nav-trigger {
    min-height: 24px;
  }
  .brand-wrap img {
    height: 50px;
    margin: 9px 0px;
  }
  .osahan-country {
    z-index: 1001;
  }
  .login-page .form-group {
    margin-bottom: 10px;
  }
  #vid {
    display: none !important;
  }
  .ext_btn {
    min-height: 56px;
    max-height: 56px;
    padding: 0;
  }
}
a:focus-visible {
    color:#00d2ff;
}
a.text-dark:focus-visible {
  color:#00d2ff !important;
}

.profile1-image {
  width: 40px !important;
  border: 1px solid #fff;
  height: 39px !important;
}
.p-link:hover {
  color: #fff !important;
}
.sp {
  display: flex;
  width: 100%;
  align-items: center;
}
.ssp {
  margin-left: auto;
  display:flex;
}
.wordfix_12 {
  word-break: break-word;
}
@media only screen and (min-width: 1200px) and (max-width: 1920px){
  .hc-nav-trigger.hc-nav-1 {
    top:auto;
    align-items: center;
  }

}
@media  (max-width: 576px){
  .restaurant-pic {
    display: block;
    margin: 9px 0 !important;
   }
}
@media (max-width: 992px){
  .restaurant-pic {
    display: block;
  }
  .sticky_sidebar {
    position: relative;
  }
}
.position-abs {
  position: absolute !important;
}
.text-italic {
  font-style: italic !important;
}